import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"

export default function Blog({data}) {
	return (
		<Layout>
			<article className="blog">
				<header className="entry-header">
					<h1 className="entry-title">AWS KMS – Keeping Secrets A Secret</h1>
					<div className="entry-meta">
						<span className="sep">Posted on </span>
						<time className="entry-date">August 11, 2017</time>
					</div>
				</header>
				<div className="entry-content">

					<p>Psst, our application has a secret!</p>
					<p>And keeping that secret a secret can often be a challenging problem.</p>
					<p>All of our secrets can have different requirements causing varying degrees of complexity for saving <i>and</i> retrieving.  As developers, it's imperative we understand <em><span>fully</span></em> the implication of a leaked secret, and then choose the appropriate level of protection.</p>
					<p>Along comes Amazon's <a href="https://aws.amazon.com/kms/">Key Management System (KMS)</a> to make our lives a little easier.</p>


					<h2>Key Management System</h2>
					<p>Amazon's <a href="https://aws.amazon.com/kms/">Key Management System (KMS)</a> makes it easy to create and control encryption keys used to encrypt our secrets.  And, as a bonus, it can integrate with AWS CloudTrail to provide an audit trail of all key usage.</p>
					<p>KMS has two primary encryption methods:</p>

					<ol>
						<li><strong>Simple Encryption</strong> is when we pass our data directly to KMS for encryption.  We can pass up to 4 KB of arbitrary data such as a database password, a configuration file or other sensitive information.  Simple Encryption is suitable for data with no strict restrictions against cloud management.</li>
						<li><strong>Envelope Encryption</strong> is when we want to manage the encryption ourselves locally using AES, but we require a data key to seed the encryption.  KMS can generate a unique encrypted data key which we can use locally for our encryption algorithm.  Envelope Encryption is suitable for regulatory or compliance requirements, such as <a href="https://aws.amazon.com/compliance/hipaa-compliance/">HIPAA</a>, that have very strict rules on where data can be managed.</li>
					</ol>


					<h2>KMS Setup</h2>
					<p>To use KMS, we first need to:</p>
					
					<ol>
						<li>Create a Customer Master Encryption Key</li>
						<li>Create an IAM role or user that has permission to use the Encryption Key</li>
					</ol>

					<h2>Simple Encryption</h2>
					<h3>Encryption:</h3>
					<p>Now, let's use the AWS CLI to encrypt a file:</p>

					<ul>
						<li>$key = A valid Encryption Key ID/ARN</li>
						<li>$sourceFile = Source file you want to encrypt</li>
						<li>$encryptedFile = Location of encrypted output file</li>
					</ul>
					
					<p><strong>Linux/OSX:</strong></p>
					<pre>
						aws kms encrypt –key-id $key –plaintext fileb://$sourceFile –output text –query CiphertextBlob | base64 –decode &gt; "$encryptedFile"
					</pre>

					<p><strong>Windows:</strong></p>
					<pre>
						aws kms encrypt –key-id $key –plaintext fileb://$sourceFile –output text –query CiphertextBlob &gt; Temp.$encryptedFile.base64
						<br />
						certutil -decode Temp.$encryptedFile.base64 $encryptedFile
					</pre>


					<p>Note the base64 decoding after the encrypt.  Both KMS encrypt and decrypt return a base64 encoded string.  However, decrypt will not take base64 as input.  If we want to decrypt our encrypted secret, we need to decode and save it as a binary file.</p>
					
					<h3>Decryption:</h3>
					<p>To decrypt the file:</p>
					<ul>
						<li>$encryptedFile = Encrypted source file you want to decrypt</li>
						<li>$decryptedFile = Location of decrypted output file</li>
					</ul>

					<p><strong>Linux/OSX:</strong></p>
					<pre>aws kms decrypt –ciphertext-blob fileb://$encryptedFile –output text –query Plaintext | base64 –decode &gt; $decryptedFile</pre>
					
					<p><strong>Windows:</strong></p>
					<pre>aws kms decrypt –ciphertext-blob fileb://$sourceFile –output text –query Plaintext –region "us-east-1" &gt; Temp.$decryptedFile.base64
					<br />
					certutil -decode Temp.$decryptedFile.base64 $decryptedFile</pre>

					<p>Voila!  Simple as pie!</p>
					
					
					<h2>Envelope Encryption</h2>
					<p>With Envelope Encryption, we'll manage the encryption of our data using AES 256 encryption algorithm.  First, we'll need to ask KMS to generate us a data key.</p>
					
					<h3>Generate Data Key:</h3>
					<p>To generate a unique Data Key:</p>
					<ul>
						<li>$key = A valid Encryption Key ID/ARN</li>
						<li>$encryptionSpec = A valid Encryption spec: AES_256 | AES_128</li>
						<li>$encryptedDataKeyFile = Location of encrypted output file</li>
					</ul>

					<pre>
						aws kms generate-data-key –key-id $key –key-spec AES_256 –output
						<br />
						text –query CiphertextBlob | base64 –decode &gt; "$encryptedDataKeyFile"
					</pre>


					<p>We can now store our encrypted Data Key in a secure spot, preferably in a location that does not have easy access to our KMS Master Key.</p>


					<h3>Decrypt Data Key:</h3>
					<p>Now to use our new generated data key, we need to decrypt it via KMS, just like we did in simple encryption:</p>
					<ul>
						<li>$encryptedDataKeyFile = Encrypted data key file you want to decrypt</li>
						<li>$decryptedFile = Location of decrypted output file</li>
					</ul>

					<pre>aws kms decrypt –ciphertext-blob fileb://$encryptedDataKeyFile –output text –query Plaintext | base64 –decode &gt; $decryptedFile</pre>

					<p>We'll use our decrypted data key whenever we want to encrypt or decrypt our actual secrets.  I'll leave the implementation of AES encryption up to you.</p>
					
					<p>Remember to clean up the unused files after our application is done with the secret.</p>
					
					<p>Or even better, download and implement the above using an appropriate <a href="https://aws.amazon.com/tools/#sdk">SDK</a>.  It's just as simple!</p>

					<h2>Additional Resources</h2>
					<ul>
						<li><a href="http://docs.aws.amazon.com/kms/latest/APIReference/Welcome.html">AWS Key Management Service API Reference</a></li>
						<li><a href="https://aws.amazon.com/tools/#sdk">Tools for Amazon Web Services</a></li>
						<li><a href="http://docs.aws.amazon.com/kms/latest/developerguide/workflow.html">How Envelope Encryption Works with Supported AWS Services</a></li>
						<li><a href="https://www.cloudreach.com/blog/aws-kms-envelope-encryption/">AWS KMS Envelope Encryption</a></li>
						<li><a href="http://techblog.invitationdigital.com/2015/07/21/aes-and-kms-in-dot-net/">Encryption in .NET with Amazon's Key Management Service</a></li>
					</ul>
				</div>
			</article>
		</Layout>
	)
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
